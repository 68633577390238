
const HomeSSADCardDetail = ({iconNumber, desc, imgBgNumber}) => {
   return (
      <>
         <div className="col-xl-6 col-lg-6 col-md-12">
            <div className="blog__item p-relative fix mb-30 white-bg">
               <div className="blog__thumb" style={{background: `url(assets/img/blog/blog-${imgBgNumber}.jpg)` , backgroundPosition: 'center', backgroundSize: 'cover' }} ></div>
               <div className="blog__content">
                  <div className="blog__meta date mb-45">                      
                     <img src={`assets/img/icon/services/services-${iconNumber}.png`} alt=""/>
                  </div>                  
                  <h3>
                     {desc}
                  </h3>                  
               </div>
            </div>
         </div>
      </>
   );
};

export default HomeSSADCardDetail;