import React from 'react';
import { FaFacebookF, FaTwitter, FaLinkedin } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import { useState } from 'react';
import SendEmail from '../SendEmail/SendEmail';
import Swal from 'sweetalert2';

const FooterDark = () => {

    const date = new Date();

    const [name, setName] = useState();
    const [email, setEmail] = useState();

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (
            name.lenght === 0 || !name.trim() ||
            email.lenght === 0 || !email.trim()) {
            return Swal.fire(
                'Ação necessária:',
                'Por favor, preencha todos os campos.',
                'warning'
            );
        }

        let response = await SendEmail({ name: name, email: email, message: "", fromNewslettersField: true });

        if (response === 200) {
            setEmail("");
            setName("");
            Swal.fire(
                'Mensagem recebida',
                'A Visão Estratégica agradece seu contato!',
                'success'
            );
        } else {
            Swal.fire(
                'Sua mensagem não foi recebida.',
                'Por favor, tente novamente mais tarde.',
                'error'
            );
        }
    }

    return (

        <footer>
            <div className="footer__area black-bg pt-100 mt-20 px-3">
                <div className="footer__top pb-45">
                    <div className="container">
                        <div className="row justify-content-around">
                            <div className="col-xl-3 col-lg-3 col-md-4 col-sm-6">
                                <div className="footer__widget mb-45 wow fadeInUp text-center" data-wow-delay=".2s">
                                    <div className="footer__widget-title mb-30">
                                        <div className="logo">
                                            <Link to="/home">
                                                <img src="assets/img/logo/logo_horizontal_transparente_fundo_escuro.png" alt="" style={{ width: '220px' }} />
                                            </Link>
                                        </div>
                                    </div>
                                    <div className="footer__widget-content footer__widget-content-2">
                                        {/* <p className="mb-20">Copyright © {date.getFullYear()} — Todos os direitos reservados por Visão Estratégica</p> */}
                                        <p className="mb-30">CNPJ — 11.893.340/0001-05 <br></br>Visão Estratégica</p>                                   
                                        <div className="footer__social footer__social-2 theme-social mb-30">
                                            <ul>
                                                <li>
                                                    <a target='blank' href="https://www.facebook.com/profile.php?id=100085761911035">
                                                        <i ><FaFacebookF /></i>
                                                        <i ><FaFacebookF /></i>
                                                    </a>
                                                </li>
                                                <li>
                                                    <a target='blank' href="https://twitter.com/VeEstrategica">
                                                        <i ><FaTwitter /> </i>
                                                        <i ><FaTwitter /> </i>
                                                    </a>
                                                </li>
                                                <li>
                                                    <a target='blank' href="https://www.linkedin.com/company/vis%C3%A3o-estrat%C3%A9gica/">
                                                        <i ><FaLinkedin /></i>
                                                        <i ><FaLinkedin /></i>
                                                    </a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-3 col-lg-3 col-md-4 col-sm-6">
                                <div className="footer__widget mb-45 wow fadeInUp text-center" data-wow-delay=".4s">
                                    <div className="footer__widget-title text-white">
                                        <h4>Empresa</h4>
                                    </div>
                                    <div className="footer__widget-content">
                                        <div className="footer__links footer__links-2">
                                            <ul>
                                                <li><Link to="/">Início</Link></li>
                                                <li><Link to="/about">Quem Somos</Link></li>
                                                <li><Link to="/serviceSSAD">Empresas Tech</Link></li>
                                                <li><Link to="/serviceSSADGov">Cidades Tech</Link></li>
                                                <li><Link to="/contact">Contato</Link></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-3 col-lg-3 col-md-4 col-sm-6">
                                <div className="services__widget border p-3 p-lg-30 text-center" style={{ borderRadius: '10px' }}>
                                    <div className="services__widget-title">
                                        <h5 className='mb-20 text-center text-white'>Receber Newsletters</h5>
                                    </div>
                                    <div className="services__widget-content">
                                        <div className="services__form">
                                            <form onSubmit={handleSubmit}>
                                                <input type="text" placeholder="Nome" onChange={(e) => { setName(e.target.value) }} value={name} />
                                                <input type="email" placeholder="Email" onChange={(e) => { setEmail(e.target.value) }} value={email} />
                                                <button className="z-btn z-btn-transparent w-100">Inscrever-me</button>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="footer__copyright footer__copyright-2">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-xl-12 text-center">
                                <div className="footer__copyright-text footer__copyright-text-2">
                                    <p>Copyright © {date.getFullYear()} — Todos os direitos reservados por <Link to="/home">Visão Estratégica</Link></p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    );
};

export default FooterDark;