import { useState } from 'react';
import { FaCheck } from 'react-icons/fa';
import BtnOrange from '../../../components/BtnOrange/BtnOrange';

const HomeSSADGovEasy = () => {

   const [screenSize] = useState(
      {
         dynamicWidth: window.innerWidth,
         dynamicHeight: window.innerHeight
      }
   );   
   return (
      <>
         <section className={screenSize.dynamicWidth <= 920 ? ("services__area-2 mt--120 pt-0 pb-30 p-relative") : ("services__area-2 mt--120 pt-80 pb-30 p-relative")}>
            <div className="container">
               <div className={screenSize.dynamicWidth <= 920 ? ("about__area-2 pt-0") : ("about__area-2 pt-130")}>
                  <div className="row">
                     <div className="col-xl-7 col-lg-6  d-none d-lg-block">
                        <div className="about__thumb-2 p-relative m-img">
                           <img src="assets/img/about/about-2.png" alt="" className='d-none d-lg-block' />
                        </div>
                        <div className='text-center mt-80 mr-130'>
                           <BtnOrange text="Contato" />
                        </div>
                     </div>
                     <div className="col-xl-5 col-lg-6">
                        <div className="about__content">
                           <div className="section__title section__title-3 mb-25">
                              <h2 className="text-orange">Com o Cidades Tech</h2>
                           </div>
                           <h3 className='text-white mb-30'>fica fácil:</h3>
                           <div className="about__list">
                              <ul>
                                 <li style={{ textAlign: 'justify' }}><span className='text-white'><i > <FaCheck /> </i>Criar um canal único de comunicação com o cidadão (mesmo utilizando diferentes plataformas) </span></li>
                                 <li style={{ textAlign: 'justify' }}><span className='text-white'><i > <FaCheck /> </i>Criar um canal único de comunicação interna de um Prefeitura, entre servidores. Pedidos de pareceres são um dos principais elementos desta interação.  </span></li>
                                 <li style={{ textAlign: 'justify' }}><span className='text-white'><i > <FaCheck /> </i>Mapear todas as demandas existentes dentro de uma Prefeitura, seus responsáveis e prazos. Inclusive demandas que são realizadas por terceiros.  </span></li>
                                 <li style={{ textAlign: 'justify' }}><span className='text-white'><i > <FaCheck /> </i>Controle todas as atividades que seus fornecedores, demandando atividades relacionadas ao objeto contratado pela municipalidade. Atividades geralmente terceiradas como poda de árvore, denúncias de mato alto em locais públicos e terrenos privados, solicitação de limpeza de rua, denúncias de pixações, pedidos de melhoria em praças e parque e qualquer outra demanda que a sua Prefeitura precise acompanhar sua execução e retornar a devolutiva ao cidadão   </span></li>
                                 <li style={{ textAlign: 'justify' }}><span className='text-white'><i > <FaCheck /> </i>Tenha um sistema completo de controle da Dívida Ativa, com conferencia de pagamento de guias e liberação de documentação. </span></li>
                              </ul>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </section>
      </>
   );
};

export default HomeSSADGovEasy;