import HomeHowIsYourCompanyItem from '../../../components/HomeHowIsYourCompanyItem/HomeHowIsYourCompanyItem';
import { FiBook, FiLock } from 'react-icons/fi';
import { FaLightbulb, FaRibbon } from 'react-icons/fa';
import { BsHouseDoor } from 'react-icons/bs';
import DidYouKnow from '../../../components/DidYouKnow/DidYouKnow';

const HomeHowIsYourCompany = () => {
   return (
      <>
         <section className="features__area pb-100 mt-1 mb-1 wow fadeInUp grey-bg-15 px-1" data-wow-delay=".5s">
            <div className="section__title section__title-3 text-center">
               <h2 className='pt-5 mb-30'>Como está a sua empresa?</h2>
            </div>
            <div className="container">
               <div className="features__inner fix mb-80">
                  <div className="row g-0 justify-content-center">
                     <HomeHowIsYourCompanyItem icon={<FiBook />} title="Solicitações de demanda corporativas descentralizadas e sem padrão?" imgBgNumber="1" />
                     <HomeHowIsYourCompanyItem icon={<FaLightbulb />} title="Relacionamento defasado com seus clientes, parceiros e colaboradores?" imgBgNumber="2" />
                     <HomeHowIsYourCompanyItem icon={<FaRibbon />} title="Falta de organização corporativa? Processos de negócio na cabeça das pessoas?" imgBgNumber="3" />
                     <HomeHowIsYourCompanyItem icon={<BsHouseDoor />} title="Meios de comunicação limitados ou pouco eficaz?" imgBgNumber="4"/>
                     <HomeHowIsYourCompanyItem icon={<FiLock />} title="Perda de prazos, dados descentralizados, falhas de atendimento?" imgBgNumber="5" />                     
                  </div>
               </div>
               <DidYouKnow/>               
            </div>
         </section>
      </>
   );
};

export default HomeHowIsYourCompany;