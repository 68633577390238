import React from "react";
import { Link } from "react-router-dom";

const AccordionSSADGovDetails = () => {

   const descrCanais = <React.Fragment>
   <p className="mb-30"> <strong>Com o SSAD Gov é possível utilizar muitas formas de dialogar com o cidadão.<br/><br/> Dentre os canais e redes destacam-se:</strong> </p>
   <ul>
       <li className='mb-15'><strong className='mr-5'>Webchat*:</strong>Converse com seus clientes e fornecedores através do site da Prefeitura. </li>
       <li className='mb-15'><strong className='mr-5'>Chatbot*:</strong>Atendimento automatizado com possibilidade de transbordo humano quando necessário. Ao invés de usar o Webchat com um operador responsável por responder ao cidadão, o chatbot automatiza boa parte das tarefas e pode ser integrado com WhatsApp e Telegram, com o mesmo conteúdo. </li>
       <li className='mb-15'><strong className='mr-5'>WhatsApp*:</strong>Da mesma forma que o atendimento via webchat e chatbot, o cidadão pode falar com a Prefeitura utilizando o mais importante sistema de mensageria do mundo. </li>
       <li className='mb-15'><strong className='mr-5'>Telegram*:</strong>Utilize o Telegram, assim como no WhatsApp, o webchat e chatbot, tudo integrado. </li>
       <li className='mb-15'><strong className='mr-5'>Instagram*:</strong>As mensagens recebidas no Instagram da Prefeitura podem entrar no mesmo padrão e sendo atendida pelos mesmos profissionais. </li>
       <li className='mb-15'><strong className='mr-5'>Facebook Messenger*:</strong> Assim como no Instagram, as mensagens do Messenger também ficam centralizadas na mesma interface. </li>
   </ul>
</React.Fragment>

   return (
      <>
         <section className="faq__area px-3">
            <div className="container p-0">
               <div className="row">
                  <div className="col-sm-12">
                     <div className="faq__accordion p-relative mb-60">

                        <div className="accordion" id="accordionExample" >
                           <div className="card accordion-item">
                              <div className="card-header accordion-header" id="acc_1" >
                                 <h5 className="mb-0">
                                    <button className="btn btn-link" data-bs-toggle="collapse" data-bs-target="#collapse_1" aria-expanded="true" aria-controls="collapse_1">
                                       Portal Web
                                    </button>
                                 </h5>
                              </div>

                              <div id="collapse_1" className="collapse show" aria-labelledby="acc_1" data-bs-parent="#accordionExample">
                                 <div className="card-body accordion-body">
                                    <p>Solicite a acompanhe suas demandas através do seu computador ou tablet.</p>
                                 </div>
                              </div>
                           </div>
                           <div className="card">
                              <div className="card-header" id="acc_2">
                                 <h5 className="mb-0">
                                    <button className="btn btn-link collapsed" data-bs-toggle="collapse" data-bs-target="#collapse_2" aria-expanded="true" aria-controls="collapse_2">
                                       Aplicativo
                                    </button>
                                 </h5>
                              </div>

                              <div id="collapse_2" className="collapse" aria-labelledby="acc_2" data-bs-parent="#accordionExample">
                                 <div className="card-body accordion-body">
                                    <p>Solicite a acompanhe suas demandas através do seu celular ou tablet, disponível para Android e iOS;</p>
                                 </div>
                              </div>
                           </div>
                           <div className="card">
                              <div className="card-header" id="acc_3">
                                 <h5 className="mb-0">
                                    <button className="btn btn-link collapsed" data-bs-toggle="collapse" data-bs-target="#collapse_3" aria-expanded="true" aria-controls="collapse_3">
                                       Sistema de Gestão
                                    </button>
                                 </h5>
                              </div>

                              <div id="collapse_3" className="collapse" aria-labelledby="acc_3" data-bs-parent="#accordionExample">
                                 <div className="card-body accordion-body">
                                    <p>Controle todos os contatos realizados, contatos recebidos, solicitações criadas, com estatísticas de tempo médio, respondido no prazo e fora do prazo e demais indicadores de gestão da operação. Tudo pode ser monitorado.</p>
                                 </div>
                              </div>
                           </div>
                           <div className="card">
                              <div className="card-header" id="acc_4">
                                 <h5 className="mb-0">
                                    <button className="btn btn-link collapsed" data-bs-toggle="collapse" data-bs-target="#collapse_4" aria-expanded="true" aria-controls="collapse_4">
                                       Central Telefônica
                                    </button>
                                 </h5>
                              </div>

                              <div id="collapse_4" className="collapse" aria-labelledby="acc_4" data-bs-parent="#accordionExample">
                                 <div className="card-body accordion-body">
                                    <p>Gerencie os contatos telefônicos com os solicitantes dos serviços através de uma interface centralizada, com disparos de ligações automatizadas, filas de espera, URA (Unidade de Resposta Audível) customizada com opções de discagem, central de controle de filas, interceptação de ligações, sussurro, e demais funcionalidades de um callcenter, sem a necessidade de ocupar um espaço físico na estrutura da Prefeitura. Tudo de forma remota e gerenciável.</p>
                                 </div>
                              </div>
                           </div>

                           <h4 className="text-orange mt-50 mb-30">Com esses canais é possível se comunicar através dos meios abaixo:</h4>
                            

                            <div className="card">
                              <div className="card-header" id="acc_5">
                                 <h5 className="mb-0">
                                    <button className="btn btn-link collapsed" data-bs-toggle="collapse" data-bs-target="#collapse_5" aria-expanded="true" aria-controls="collapse_5">
                                       Mensageria
                                    </button>
                                 </h5>
                              </div>

                              <div id="collapse_5" className="collapse" aria-labelledby="acc_5" data-bs-parent="#accordionExample">
                                 <div className="card-body accordion-body">
                                    <p>Envie mensagens personalizadas a perfis de usuários que você mesmo pode configurar. É possível saber quem recebeu, quem visualizou e inclusive quem não recebeu (muito provavelmente porque o número não está mais ativo). As mensagens podem ser enviadas por push dentro do aplicativo, SMS para celulares e tablet.</p>
                                 </div>
                              </div>
                           </div>
                           <div className="card">
                              <div className="card-header" id="acc_6">
                                 <h5 className="mb-0">
                                    <button className="btn btn-link collapsed" data-bs-toggle="collapse" data-bs-target="#collapse_6" aria-expanded="true" aria-controls="collapse_6">
                                       Canais e Redes Sociais
                                    </button>
                                 </h5>
                              </div>

                              <div id="collapse_6" className="collapse" aria-labelledby="acc_6" data-bs-parent="#accordionExample">
                                 <div className="card-body accordion-body">
                                    {descrCanais}
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                     <Link className="z-btn-home z-btn-transparent-home d-grid" to="/contact">Tenho uma dúvida</Link>
                  </div>
               </div>
            </div>
         </section>
      </>
   );
};

export default AccordionSSADGovDetails;