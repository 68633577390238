import React from "react";
import CommonPageHeader from "../../components/CommonPageHeader/CommonPageHeader";
import FooterDark from "../../components/shared/FooterDark";
import PageHelmet from "../../components/shared/PageHelmet";
import HomeOneHeader from "../Home/HomeOneHeader/HomeOneHeader";
import HomeSSADGovAccordionHeader from "../Home/HomeSSADGovAccordionHeader/HomeSSADGovAccordionHeader"
import HomeSSADGovFirstText from "../Home/HomeSSADGovFirstText/HomeSSADGovFirstText";

const ServiceSSADGov = () => {
    
    return (
        <>
            <PageHelmet pageTitle="Cidades Tech"/>

            <HomeOneHeader />
            <CommonPageHeader title='Sistema de Solicitação e Acompanhamento de Demandas para Governos' subtitle='Cidades Tech' />            
            <HomeSSADGovFirstText/>
            <HomeSSADGovAccordionHeader />  

            <FooterDark />
        </>
    );
}

export default ServiceSSADGov