import HomeSSADGovEasy from "../../Home/HomeSSADGovEasy/HomeSSADGovEasy";

const HomeSSADGovFirstText = () => {
   
   return (
      <>
         <section className="testimoinal__area gradient-bg mt-1">
            <div className="container">
               <div className="row">
                  <div className="col-xl-5 col-lg-6">
                     <div className="testimonial__content px-3 pt-90 pb-175">
                        <div className="section__title section__title-3 mb-30">                           
                           <h2 className="text-orange">Cidades Tech</h2>
                        </div>
                        <h3 className="white-color lh-base" style={{textAlign: 'justify'}}>Com o Cidades Tech todos os serviços atrelados à Ouvidoria, Canal 156, gestão de benefícios sociais e demais atividades que o cidadão pode solicitar para a Prefeitura pode ser feito no mesmo local, com um padrão estabelecido. Esse mesmo sistema pode ser utilizado internamente para gerenciar demandas entre secretarias, demandas do poder público com entidades parceiras, entre outros. </h3>                        
                     </div>
                  </div>
                  <div className="col-xl-7 col-lg-6">
                     <div className="testimonial__thumb m-img text-end pt-120 " >
                        <img src="assets/img/testimonial/testimonial-2.png" alt="testimonbial" />
                     </div>
                  </div>
               </div>
               <div className="row mt-40">
                  <HomeSSADGovEasy />
               </div>
            </div>
         </section>
      </>
   );
};

export default HomeSSADGovFirstText;