import { CgArrowLongRight } from 'react-icons/cg';
import { FaCheck } from 'react-icons/fa';
import { Link } from 'react-router-dom';

const HomeAbout = () => {
   return (
      <>
         <section className="about__area pb-30 pt-100">
            <div className="container">
               <div className="row">
                  <div className="col-xl-6 pr-0 col-lg-6">
                     <div className="about__thumb m-img">
                        <img src="assets/img/about/about-3.jpg" alt="" />
                     </div>
                  </div>
                  <div className="col-xl-5 offset-xl-1 col-lg-5 offset-lg-1">
                     <div className="about__content">
                        <div className="section__title mb-25">
                           <span>Quem somos</span>
                           <h2 className='text-orange'>"...Nós transformamos vidas..."</h2>
                        </div>
                        <p>A Visão Estratégica (VE para os íntimos) é empresa de tecnologia que nasceu do sonho de
                           transformar o mundo em um lugar melhor, mais responsável e eficiente.
                           Cada passo dado pela VE transformou a vida de seus clientes através do desenvolvimento de
                           soluções simples com alto impacto estratégico.</p>
                        <div className="about__list">
                           <ul>
                              <li ><span className='text-orange'><i > <FaCheck /> </i>Compromisso social</span></li>
                              <li ><span className='text-orange'><i > <FaCheck /> </i>Idéias inovadoras</span></li>
                              <li ><span className='text-orange'><i > <FaCheck /> </i>Competência profissional</span></li>
                           </ul>
                        </div>
                        <div className='row'>
                           <div className='col-lg-6'>
                              <Link to="/ServiceSSAD" className="z-btn d-grid mb-2" >Empresas Tech<i > <CgArrowLongRight /> </i></Link>
                           </div>
                           <div className='col-lg-6'>
                              <Link to="/ServiceSSADGov" className="z-btn d-grid mb-2" >Cidades Tech<i > <CgArrowLongRight /> </i></Link>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </section>
      </>
   );
};

export default HomeAbout;