import React from 'react';
import AccordionSSADGovDetails from '../../../components/Accordions/AccordionSSADGovDetails';

const HomeSSADGovAccordionHeader = () => {
   return (
      <>
         <section className="capabilities__area p-relative gradient-bg mt-1 pt-90 pb-90 fix">

            <div className="capabilities__shape p-absolute wow fadeInLeft" >
               <img src="assets/img/capabilities/capabilities-shape.png" alt="shape" style={{ 'opacity': '0.1' }} />
            </div>
            <div className="container">
               <div className="row">
                  <div className="col-xl-5 col-lg-6">
                     <div className="capabilities__content wow fadeInUp px-3" data-wow-delay=".4s">
                        <div className="section__title .section__title-3 h2 mb-60">
                           <h2 className="text-orange">Com o Cidades Tech</h2>
                           <h4 className="white-color mt-30 lh-base" style={{ textAlign: 'justify' }}>é possível manter uma comunicação ativa por e-mail, SMS, webchat no site da Prefeitura com transbordo para operadores, chatbots para automação de atividades, gestão de contatos via WhatsApp, Telegram e através das redes sociais Instagram e Facebook Messenger. Tudo centralizado e com total rastreabilidade. Atualmente utilizamos os módulos abaixo para gerenciar todas essas comunicações: </h4>
                        </div>
                     </div>                     
                     <AccordionSSADGovDetails/>
                  </div>
               </div>
            </div>
            <div className="capabilities__thumb p-absolute" style={{
               background: `url(assets/img/capabilities/smart_city.png)`
               , backgroundPosition: 'center', backgroundSize: 'cover'
            }}></div>
         </section>
      </>
   );
};

export default HomeSSADGovAccordionHeader;