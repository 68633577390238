
import HomeOneHeader from './HomeOneHeader/HomeOneHeader';
import HomeHeroSlider from './HomeHeroArea/HomeHeroSlider';
import HomeHowIsYourCompany from './HomeHowIsYourCompany/HomeHowIsYourCompany';
import FooterDark from '../../components/shared/FooterDark';
import PageHelmet from '../../components/shared/PageHelmet';
import HomeSelectServices from '../Home/HomeSelectServices/HomeSelectServices';
import ContactInfoArea from '../Contact/ContactInfoArea/ContactInfoArea'

const Home = () => {
    return (
        <>
            <PageHelmet pageTitle="Início" />

            <HomeOneHeader/>
            <HomeHeroSlider/>
            <HomeHowIsYourCompany />
            <HomeSelectServices />
            <ContactInfoArea />
            
            <FooterDark/>
        </>
    );
};

export default Home;