import { BrowserRouter, Routes, Route } from "react-router-dom";
import ScrollTop from "./components/ScrollTop";
import About from "./pages/About/About";
import Contact from "./pages/Contact/Contact";
import Home from './pages/Home/Home';
import ServiceSSADGov from "./pages/Services/ServiceSSADGov";
import ServiceSSAD from "./pages/Services/ServiceSSAD";

function App() {
  return (
    <>
      <BrowserRouter>      
        <ScrollTop/>
        <Routes>
            <Route path="/" element={<Home/>} />
            <Route path="/home" element={<Home/>} />            
            <Route path="/about" element={<About/>} />
            <Route path="/contact" element={<Contact/>} />            
            <Route path="/serviceSSAD" element={<ServiceSSAD/>} />            
            <Route path="/serviceSSADGov" element={<ServiceSSADGov/>} /> 
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
