import React from 'react';
import BtnOrange from '../../../components/BtnOrange/BtnOrange'

const HomeSSADMakeRequests = () => {
   return (
      <>
         <section className="faq__area p-relative pb-30 mb-30 grey-bg-15 px-0">
            <div className="faq__thumb" style={{ background: `url(assets/img/faq/faq-2.jpg)`, backgroundPosition: 'center', backgroundSize: 'cover' }}></div>
            <div className="container px-0">
               <div className="row">
                  <div className="col-xl-8 offset-xl-4 col-lg-6 offset-lg-6 col-md-7 offset-md-5">
                     <div className="faq__wrapper">
                        <div className="section__title section__title-3 mb-40 wow fadeInUp" data-wow-delay=".2s">
                           <h4 className='text-orange'>Faça solicitações utilizando a plataforma que desejar. </h4>
                           <h2>Oferecemos aos nossos clientes:</h2>
                        </div>

                        <div className="accordion" id="accordionExample">

                           <div className="card accordion-item">
                              <div className="accordion-header" id="headingThree">
                                 <h5 className="mb-0">
                                    <button className="btn btn-link collapsed" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="true" aria-controls="collapseThree">
                                       Sistema de gestão
                                    </button>
                                 </h5>
                              </div>
                              <div id="collapseThree" className="accordion-collapse collapse show" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                                 <div className="card-body accordion-body fw-bold">
                                    Controle todos os contatos realizados, contatos recebidos, solicitações criadas, com estatísticas de tempo médio, respondido no prazo e fora do prazo e demais indicadores de gestão da operação. Nunca mais você perderá uma demanda.
                                 </div>
                              </div>
                           </div>

                           <div className="card accordion-item">
                              <div className="accordion-header" id="headingOne">
                                 <h5 className="mb-0">
                                    <button className="btn btn-link collapsed" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="false" aria-controls="collapseOne">
                                       Portal Web
                                    </button>
                                 </h5>
                              </div>

                              <div id="collapseOne" className="accordion-collapse collapse" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                 <div className="card-body accordion-body fw-bold">
                                    Mostre a seus clientes que a sua empresa é organizada, que tem processos de gestão bem definidos e um padrão para que a demanda entre para dentro da instituição. O Portal Web apresentará todos os serviços que a sua empresa disponibiliza, quais são os processos envolvidos, documentos necessários, responsabilidades, SLA’s, tudo personalizado, com uma mecânica de configuração simples e fácil de usar.
                                 </div>
                              </div>
                           </div>

                           <div className="card accordion-item">
                              <div className="accordion-header" id="headingTwo">
                                 <h5 className="mb-0">
                                    <button className="btn btn-link collapsed" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                       Aplicativo
                                    </button>
                                 </h5>
                              </div>
                              <div id="collapseTwo" className="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                                 <div className="card-body accordion-body fw-bold">
                                    Publicamos um aplicativo com a logo e cores da sua empresa, completamente integrado com o Portal Web e o Sistema de Gestão. Os usuários administradores podem mudar o conteúdo de tudo que aparece no app. Disponível para Android e iOS.
                                 </div>
                              </div>
                           </div>

                           <div className="card accordion-item">
                              <div className="accordion-header" id="headingFour">
                                 <h5 className="mb-0">
                                    <button className="btn btn-link collapsed" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                                       Central Telefônica
                                    </button>
                                 </h5>
                              </div>
                              <div id="collapseFour" className="accordion-collapse collapse" aria-labelledby="headingFour" data-bs-parent="#accordionExample">
                                 <div className="card-body accordion-body fw-bold">
                                    Gerenciei os contatos telefônicos com os solicitantes dos serviços através de uma interface centralizada, com disparos de ligações automatizadas, filas de espera, URA (Unidade de Resposta Audível) customizada com opções de discagem, central de controle de filas, interceptação de ligações, sussurro, e demais funcionalidades de um callcenter, sem a necessidade de ocupar um espaço físico na empresa. Todos os operadores e gestores podem trabalhar remotamente com total rastreabilidade das atividades de seus colaboradores.
                                 </div>
                              </div>
                           </div>

                           <div className="card accordion-item">
                              <div className="accordion-header" id="headingFive">
                                 <h5 className="mb-0">
                                    <button className="btn btn-link collapsed" data-bs-toggle="collapse" data-bs-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
                                       Mensageria
                                    </button>
                                 </h5>
                              </div>
                              <div id="collapseFive" className="accordion-collapse collapse" aria-labelledby="headingFive" data-bs-parent="#accordionExample">
                                 <div className="card-body accordion-body fw-bold">
                                    Envie mensagens personalizadas a perfis de usuários que você mesmo pode configurar. É possível saber quem recebeu, quem visualizou e inclusive quem não recebeu (muito provavelmente porque o número não está mais ativo). As mensagens podem ser enviadas por push dentro do aplicativo, SMS para celulares e tablet.
                                 </div>
                              </div>
                           </div>

                           <div className="card accordion-item">
                              <div className="accordion-header" id="headingSix">
                                 <h5 className="mb-0">
                                    <button className="btn btn-link collapsed" data-bs-toggle="collapse" data-bs-target="#collapseSix" aria-expanded="false" aria-controls="collapseSix">
                                       Webchat*
                                    </button>
                                 </h5>
                              </div>
                              <div id="collapseSix" className="accordion-collapse collapse" aria-labelledby="headingSix" data-bs-parent="#accordionExample">
                                 <div className="card-body accordion-body fw-bold">
                                    Converse com seus clientes pelo site, sem precisar redirecionar para o WhatsApp. Com incorporação de fluxos automatizados (chatbot) e estatísticas de atendimento.
                                 </div>
                              </div>
                           </div>

                           <div className="card accordion-item">
                              <div className="accordion-header" id="headingSeven">
                                 <h5 className="mb-0">
                                    <button className="btn btn-link collapsed" data-bs-toggle="collapse" data-bs-target="#collapseSeven" aria-expanded="false" aria-controls="collapseSeven">
                                       Chatbot*
                                    </button>
                                 </h5>
                              </div>
                              <div id="collapseSeven" className="accordion-collapse collapse" aria-labelledby="headingSeven" data-bs-parent="#accordionExample">
                                 <div className="card-body accordion-body fw-bold">
                                    Fluxos automatizados ajudam a liberar seus operadores para atividades mais importantes.
                                 </div>
                              </div>
                           </div>

                           <div className="card accordion-item">
                              <div className="accordion-header" id="headingEight">
                                 <h5 className="mb-0">
                                    <button className="btn btn-link collapsed" data-bs-toggle="collapse" data-bs-target="#collapseEight" aria-expanded="false" aria-controls="collapseEight">
                                       Whatsapp*
                                    </button>
                                 </h5>
                              </div>
                              <div id="collapseEight" className="accordion-collapse collapse" aria-labelledby="headingEight" data-bs-parent="#accordionExample">
                                 <div className="card-body accordion-body fw-bold">
                                    Da mesma forma que o atendimento via webchat e chatbot, seus clientes e fornecedores podem falar com a sua empresa utilizando o mais importante sistema de mensageria do Brasil. O mesmo fluxo automatizado pode ser utilizado em diferentes meios de comunicação.
                                 </div>
                              </div>
                           </div>

                           <div className="card accordion-item">
                              <div className="accordion-header" id="headingNine">
                                 <h5 className="mb-0">
                                    <button className="btn btn-link collapsed" data-bs-toggle="collapse" data-bs-target="#collapseNine" aria-expanded="false" aria-controls="collapseNine">
                                       Telegram*
                                    </button>
                                 </h5>
                              </div>
                              <div id="collapseNine" className="accordion-collapse collapse" aria-labelledby="headingNine" data-bs-parent="#accordionExample">
                                 <div className="card-body accordion-body fw-bold">
                                    Utilize o Telegram, assim como no WhatsApp, o webchat e chatbot, tudo integrado.
                                 </div>
                              </div>
                           </div>

                           <div className="card accordion-item">
                              <div className="accordion-header" id="headingTen">
                                 <h5 className="mb-0">
                                    <button className="btn btn-link collapsed" data-bs-toggle="collapse" data-bs-target="#collapseTen" aria-expanded="false" aria-controls="collapseTen">
                                       Instagram*
                                    </button>
                                 </h5>
                              </div>
                              <div id="collapseTen" className="accordion-collapse collapse" aria-labelledby="headingTen" data-bs-parent="#accordionExample">
                                 <div className="card-body accordion-body fw-bold">
                                    As mensagens recebidas no Instagram da sua empresa podem entrar no mesmo padrão e sendo atendida pelos mesmos profissionais.
                                 </div>
                              </div>
                           </div>

                           <div className="card accordion-item">
                              <div className="accordion-header" id="headingEleven">
                                 <h5 className="mb-0">
                                    <button className="btn btn-link collapsed" data-bs-toggle="collapse" data-bs-target="#collapseEleven" aria-expanded="false" aria-controls="collapseEleven">
                                       Facebook Messenger*
                                    </button>
                                 </h5>
                              </div>
                              <div id="collapseEleven" className="accordion-collapse collapse" aria-labelledby="headingEleven" data-bs-parent="#accordionExample">
                                 <div className="card-body accordion-body fw-bold">
                                    Assim como no Instagram, as mensagens do Messenger também ficam centralizadas na mesma interface, compartilhando os mesmos profissionais de apoio ao cliente.
                                 </div>
                              </div>
                           </div>
                        </div>
                        <p className='text-muted mb-30'>* licenciamento adicional necessário </p>
                        <BtnOrange text="Tenho uma dúvida" />
                     </div>
                  </div>
               </div>
            </div>
         </section>
      </>
   );
};

export default HomeSSADMakeRequests;