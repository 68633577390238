
import HomeDidYouKnowSingleCard from '../../components/HomeDidYouKnowSingleCard/HomeDidYouKnowSingleCard'

const DidYouKnow = () => {
   return (
      <>
         <section className="cta__area pt-45 pb-60" style={{
            background: `url(assets/img/cta/cta-bg-1.jpg)`
            , backgroundPosition: 'center', backgroundSize: 'cover'
         }} >
            <div className="container p-relative">
               <div className="row">
                  <div className="col-xl-10 offset-xl-1">
                     <div className="cta__content text-center">
                        <h3 className='text-orange mt-0 mb-30'>Você sabia?</h3>
                        <h1>96% dos clientes compartilham suas experiências ruins com outras pessoas.</h1>
                        <h3 className='text-orange mb-20'>4 consequências impactantes geradas por atendimentos insatisfatórios: </h3>
                        <div className="row g-0">

                           <HomeDidYouKnowSingleCard image="1" title="1ª" subtitle="Diminuição gradativa do faturamento." />
                           <HomeDidYouKnowSingleCard image="2" title="2ª" subtitle="Prejuízos à imagem da empresa." />
                           <HomeDidYouKnowSingleCard image="3" title="3ª" subtitle="Insatisfações dos clientes indo para as redes sociais." />
                           <HomeDidYouKnowSingleCard image="4" title="4ª" subtitle="Diminuição do rendimento das equipes por retrabalho." />

                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </section>
      </>
   );
};

export default DidYouKnow;