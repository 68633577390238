import { useState } from "react";
import { FaCheck } from "react-icons/fa";
import SendEmail from "../../../components/SendEmail/SendEmail";
import Swal from "sweetalert2";

const ContactArea = () => {

   const [name, setName] = useState("");
   const [email, setEmail] = useState("");
   const [message, setMessage] = useState("");



   const handleSubmit = async (e) => {
      e.preventDefault();
      
      if (
         message.length === 0 || !message.trim() ||
         name.length === 0 || !name.trim() ||
         email.length === 0 || !email.trim()) {
          return Swal.fire(
            'Ação necessária:',
            'Por favor, preencha todos os campos.',
            'warning'
          );         
      }
      
      let response = await SendEmail({ name: name, email: email, message: message, fromNewslettersField: false });

      if (response === 200) {
         setEmail("");
         setName("");
         setMessage("");
         Swal.fire(
            'Mensagem recebida',
            'A Visão Estratégica agradece seu contato!',
            'success'
          )
      } else {
         Swal.fire(
            'Sua mensagem não foi recebida.',
            'Por favor, tente novamente mais tarde.',
            'error'
          )
      }
   }

   return (
      <>
         <section className="contact__area">
            <div className="container-fluid p-0">
               <div className="row">
                  <div className="col-xl-5">
                     <div className="about__content">
                        <h3 className="mb-30" style={{ textAlign: 'justify' }}>Sua mensagem será respondida, em até 4 horas, por consultores preparados para atendê-lo em qualquer das situações:</h3 >
                        <div className="about__list">
                           <ul>
                              <li ><span><i > <FaCheck /> </i>Tirar dúvidas</span></li>
                              <li ><span><i > <FaCheck /> </i>Demonstrar serviços</span></li>
                              <li ><span><i > <FaCheck /> </i>Enviar cotações</span></li>
                           </ul>
                        </div>
                     </div>
                  </div>

                  <div className="col-xl-7">
                     <div className="px-md-5">
                        <div className="post-comment-form">
                           <form onSubmit={handleSubmit}>
                              <div className="row">
                                 <div className="col-xl-12 p-0">
                                    <div className="post-input">
                                       <textarea placeholder="Sua mensagem..." onChange={(e) => { setMessage(e.target.value) }} required value={message}></textarea>
                                    </div>
                                 </div>
                                 <div className="col-xl-6 col-md-6 p-0 ps-md-0 pe-md-1">
                                    <div className="post-input">
                                       <input type="text" placeholder="Seu nome" onChange={(e) => { setName(e.target.value) }} required value={name} />
                                    </div>
                                 </div>
                                 <div className="col-xl-6 col-md-6 p-0 ps-md-1 pe-md-0">
                                    <div className="post-input pe-0">
                                       <input type="email" placeholder="Seu Email" onChange={(e) => { setEmail(e.target.value) }} required value={email} />
                                    </div>
                                 </div>
                                 <button type="submit" className="z-btn">Enviar Mensagem</button>
                              </div>
                           </form>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </section>
      </>
   );
};

export default ContactArea;