import React from 'react';
import ContactArea from '../ContactArea/ContactArea';

const ContactInfoArea = () => {
   return (
      <>
         <section className="contact__help p-relative pt-80 pb-30">
            <div className="container">
               <div className="card shadow" style={{ borderRadius: '10px' }}>
                  <div className="card-body" style={{ padding: '30px' }}>
                     <div className="row">
                        <div className="col-xl-12">
                           <div className="section__title section__title-3 mb-10 text-center">
                              <h2 className='mb-30'>Ficou com dúvidas ou quer saber mais?</h2>
                              <h4 className='text-orange mb-30'>Entre em contato conosco:</h4>
                           </div>
                           <ContactArea />
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </section>
      </>
   );
};

export default ContactInfoArea;