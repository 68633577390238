import emailJs from "@emailjs/browser";

const SendEmail = ({ name, email, message, fromNewslettersField }) => {

    let validateMessage = "";
    let validateTemplate = "";

    if (fromNewslettersField) {
        validateMessage = `Incluir email ${email} na lista envio de Newsletters.`;
        validateTemplate = "template_255677y";
    } else {
        validateMessage = message;
        validateTemplate = "template_4p6u1rs"
    }

    const templateParams = {
        from_name: name,
        email: email,
        message: validateMessage
    }

    return (
        emailJs.send("service_xp2gjan", validateTemplate, templateParams, "qpd2mg8FjSW1eW1MN")
            .then((response) => {
                return (response.status);
            }, (err) => {
                return (err)
            }
            )        
    )
}

export default SendEmail
