import HomeSSADCardDetail from '../../../components/HomeSSADCardDetail/HomeSSADCardDetail';
import HomeSSADMakeRequests from '../HomeSSADMakeRequests/HomeSSADMakeRequests';

const HomeSSADWeHaveSolutions = () => {
   return (
      <>
         <section className="services__area pt-90 pb-30">
            <div className="container grey-bg-15 px-5 p-4">
               <div className="row">
                  <div className="col-xl-10 offset-xl-1 col-lg-10 offset-lg-1">
                     <div className="section__title section__title-3 text-center mb-60 wow fadeInUp mt-30" data-wow-delay=".2s">
                        <h2 className='mb-30'>Nós temos soluções que aumentam performance, organização e resultados.</h2>
                        <h4 className='text-orange'>Ganhe em produtividade e rendimento, através de gestão maximizada e intuitiva.</h4>
                     </div>
                  </div>
               </div>              

               <div className="row mb-80">

                  <HomeSSADCardDetail iconNumber="5"  desc="Utilize nosso sistema de solicitações em qualquer processo de sua empresa, interno ou externo." imgBgNumber="1" />
                  <HomeSSADCardDetail iconNumber="6"  desc="Mapeie todas as demandas existentes em uma organização, seus responsáveis e prazos." imgBgNumber="2" />
                  <HomeSSADCardDetail iconNumber="7"  desc="Gerencie com facilidade, o relacionamento com clientes, colaboradores e fornecedores." imgBgNumber="3" />
                  <HomeSSADCardDetail iconNumber="8"  desc="Centralize todos os estágios e processos de sua empresa em um único lugar." imgBgNumber="4" />

               </div>
               <HomeSSADMakeRequests />
            </div>
         </section>
      </>
   );
};

export default HomeSSADWeHaveSolutions;