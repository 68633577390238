import { Link } from 'react-router-dom';
import Slider from "react-slick";

const HomeSelectServices = () => {
   // slick setting
   const settings = {
      autoplay: false,
      autoplaySpeed: 10000,
      dots: false,
      fade: false,
      arrows: true,
      slidesToShow: 2,
      responsive: [
         {
            breakpoint: 767,
            settings: {
               slidesToShow: 1,
               initialSlide: 1
            }
         },
      ]
   }
   const testimonialData = [
      {
         id: 1,
         title: <><h3 className='text-orange'>Empresas Tech</h3><h4>Nosso sistema de solicitação e acompanhamento de demandas se adequa à empresas de todos os portes.</h4></>,
         img: "assets/img/project/project-1.png",
         link: "/serviceSSAD"
      },
      {
         id: 2,
         title: <><h3 className='text-orange'>Cidades Tech</h3><h4>Nosso sistema de solicitação e acompanhamento de demandas desenvolvido para cidades visa abrager questões ligadas fortemente á governos.</h4></>,
         img: "assets/img/project/project-2.png",
         link: "/serviceSSADGov"
      },

   ]
   return (
      <>
         <section className="project__area pt-30 pb-30 mb-1 bg-blue-ve">
            <div className="container">
               <div className="row">
                  <div className="col-xl-12">
                     <div>
                        <h3 className="text-orange pt-30 mb-30 text-center">Precisando de uma solução?</h3>
                        <h1 className="text-white mb-30 text-center">Disponha agora de máxima eficiência:</h1>
                     </div>
                     <Slider className='project__slider' {...settings}>
                        {

                           testimonialData.map((testimonial) => {
                              return <div key={testimonial.id} className="project__item grey-bg-19 mb-60 product-card">
                                 <Link to={testimonial.link}>
                                    <div className="project__content mb-35">
                                       <div className="project__no">
                                          <h5>{testimonial.id}</h5>
                                       </div>
                                       {testimonial.title}
                                    </div>
                                    <div className="project__thumb m-img">
                                       <img src={testimonial.img} alt="" />
                                    </div>
                                 </Link>
                              </div>
                           })
                        }
                     </Slider>
                  </div>
               </div>
            </div>
         </section>
      </>
   );
};

export default HomeSelectServices;