import React from 'react';
import { Link } from 'react-router-dom';
import { Offcanvas } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';
import { FaFacebookF, FaTwitter, FaLinkedin } from 'react-icons/fa';

const Sidebar = ({ show, handleClose }) => {

   return (
      <>
         <div >
            <Offcanvas show={show} onHide={handleClose} placement='end' className='side__bar'>
               <Offcanvas.Header closeButton>
                  <div className="logo">
                     <Link to="/home">
                        <img src="assets/img/logo/simbolo_transparente_fundo_escuro.png" alt="logo" className='w-25 px-3' />
                     </Link>
                  </div>
               </Offcanvas.Header>

               <Offcanvas.Body>
                  <section>
                     <div className="p-0">
                        <div className="sidebar__tab">
                           <ul className="nav nav-tabs" id="sidebar-tab" role="tablist">
                              <li className="nav-item">
                                 <Link className="nav-link active" id="menu-tab" data-bs-toggle="tab" to="#menu" role="tab" aria-controls="menu" aria-selected="true">menu</Link>
                              </li>
                              <li className="nav-item">
                                 <Link className="nav-link" id="info-tab" data-bs-toggle="tab" to="#info" role="tab" aria-controls="info" aria-selected="false">info</Link>
                              </li>
                           </ul>
                        </div>
                        <div className="sidebar__content">

                           <div className="tab-content" id="sidebar-tab-content">
                              <div className="tab-pane fade show active" id="menu" role="tabpanel" aria-labelledby="menu-tab">

                                 <div className='side_navBar fs-5'>                                    
                                    <div>
                                       <NavLink to="/home">Início </NavLink>
                                    </div>
                                    <hr className='my-2' />
                                    <div>
                                       <NavLink to="/about">Quem somos </NavLink>
                                    </div>
                                    <hr className='my-2' />
                                    <div>
                                       <NavLink to="/serviceSSAD">Empresas Tech </NavLink>
                                    </div>
                                    <hr className='my-2' />
                                    <div>
                                       <NavLink to="/serviceSSADGov">Cidades Tech </NavLink>
                                    </div>
                                    <hr className='my-2' />
                                    <div>
                                       <NavLink to="/contact">Contato </NavLink>
                                    </div>
                                 </div>
                              </div>

                              <div className="tab-pane fade" id="info" role="tabpanel" aria-labelledby="info-tab">
                                 <div className="sidebar__info">                                    
                                    <p>Caso tenha alguma dúvida, queira uma demonstração ou receber uma cotação, não hesite em enviar-nos sua mensagem. Estamos prontos para atendê-lo! </p>
                                    <Link to="/contact" className="z-btn-home z-btn-transparent-home d-grid">Contato</Link>
                                    
                                    <div className="footer__social footer__social-2 theme-social mb-30 mt-30 text-center">
                                    <ul>                                                    
                                                    <li>
                                                        <a target='blank' href="https://www.facebook.com/profile.php?id=100085761911035">
                                                            <i ><FaFacebookF /></i>
                                                            <i ><FaFacebookF /></i>
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a target='blank' href="https://twitter.com/VeEstrategica">
                                                            <i ><FaTwitter /> </i>
                                                            <i ><FaTwitter /> </i>
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a target='blank' href="https://www.linkedin.com/company/vis%C3%A3o-estrat%C3%A9gica/">
                                                            <i ><FaLinkedin /></i>
                                                            <i ><FaLinkedin /></i>
                                                        </a>
                                                    </li>
                                                </ul>
                                    </div>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                  </section>

               </Offcanvas.Body>
            </Offcanvas>
         </div>
      </>
   );
};

export default Sidebar;