import React from "react";
import PageHelmet from "../../components/shared/PageHelmet";
import CommonPageHeader from "../../components/CommonPageHeader/CommonPageHeader";
import FooterDark from "../../components/shared/FooterDark";
import HomeOneHeader from "../Home/HomeOneHeader/HomeOneHeader";
import HomeSSADWeHaveSolutions from "../Home/HomeSSADWeHaveSolutions/HomeSSADWeHaveSolutions";

const ServiceSSAD = () => {
    return (        
        <>
            <PageHelmet pageTitle="Empresas Tech" />

            <HomeOneHeader />
            <CommonPageHeader title='Sistema de Solicitação e Acompanhamento de Demandas' subtitle= 'Empresas Tech'/>
            <HomeSSADWeHaveSolutions />  

            <FooterDark />
        </>
    );
}

export default ServiceSSAD