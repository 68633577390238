import React from 'react';
import CommonPageHeader from '../../components/CommonPageHeader/CommonPageHeader';
import FooterDark from '../../components/shared/FooterDark';
import PageHelmet from '../../components/shared/PageHelmet';
import HomeAbout from '../Home/HomeAbout/HomeAbout';
import HomeOneHeader from '../Home/HomeOneHeader/HomeOneHeader';

const About = () => {
   return (
      <>
         <PageHelmet pageTitle="Quem somos" />
         <HomeOneHeader/>
         <CommonPageHeader title="Quem somos" subtitle="Quem somos" />
         <HomeAbout/> 
         <FooterDark/>
      </>
   );
};

export default About;